<template>
  <div class="member-list" :class="{ skeleton: !state.loaded }">
    <div class="header row">
      <div class="left col-lg-6">
        <div class="total">
          <span>전체 </span>
          <span>{{ $lib.getNumberFormat(state.total) }}명</span>
        </div>
        <div class="conditions">
          <div class="condition" v-for="(c, key, idx) in state.conditions" :key="idx">
            <span>{{ getConditionFormat(key, c) }}</span>
            <span class="remove" @click="removeCondition(key)">&times;</span>
          </div>
        </div>
      </div>
      <form class="search col-lg-6" @submit.prevent="setCondition(state.search.key, state.search.value)">
        <div class="period d-flex">
          <select class="form-control border-focus-purple font-xs" :disabled="!state.loaded" v-model="state.period.key" @change="setPeriod()">
            <option value="createDate">가입일</option>
            <option value="loginDate">최근 로그인 날짜</option>
          </select>
          <Date componentNameSuffix="S" :id="`${component.name}`" inputClass="border-focus-purple font-xs" :value.sync="state.period.from" placeholder="시작일" :func="setPeriod" :disabled="!state.loaded"/>
          <Date componentNameSuffix="E" :id="`${component.name}`" inputClass="border-focus-purple font-xs" :value.sync="state.period.to" placeholder="종료일" :func="setPeriod" :disabled="!state.loaded"/>
        </div>
        <div class="row">
          <div class="col-3 pr-0">
            <select class="form-control border-focus-purple font-xs" :disabled="!state.loaded" v-model="state.search.key">
              <option :value="s.name" v-for="(s, idx) in search" :key="idx">{{ s.title }}</option>
            </select>
          </div>
          <div class="col-5 pl-0 pr-0">
            <input type="text" class="form-control border-focus-purple font-xs" :disabled="!state.loaded" v-model="state.search.value"/>
          </div>
          <div class="col-2 pl-0 pr-0">
            <button class="px-0 w-100 btn btn-purple font-xs" :disabled="!state.loaded" type="submit">검색</button>
          </div>
          <div class="col-2">
            <div class="dropdown">
              <button class="btn m-0" :id="`${component.name}DropDown`" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="필터 설정하기">
                <i class="fa fa-filter"></i>
              </button>
              <div class="dropdown-menu" :aria-labelledby="`${component.name}DropDown`">
                <div class="section" v-for="(c, idx1) in conditions" :key="idx1">
                  <div class="title font-xs" v-if="c.title">
                    <b>{{ c.title }}</b>
                  </div>
                  <span class="dropdown-item" v-for="(i, idx2) in c.items" :key="idx2" @click="setCondition((c.name || i.name), (c.name ? i.name : i.value))"
                        :class="{active: $route.query[c.name || i.name] === (c.name? i.name : i.value)}">
                  {{ i.title }}
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="body">
      <div class="table-responsive" :class="{skeleton: !state.loaded}" v-if="state.list.length">
        <table class="table table-hover">
          <thead>
          <tr>
            <th class="text-center">
              <span>번호</span>
            </th>
            <th>
              <span>아이디</span>
            </th>
            <th>
              <span>이름</span>
            </th>
            <th>
              <span>기업명</span>
            </th>
            <th>
              <span>휴대전화</span>
            </th>
            <th>
              <span>구분</span>
            </th>
            <th>
              <span>상태</span>
            </th>
            <th>
              <span>가입일</span>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(m, idx) in state.list" :key="idx">
            <td class="text-center">
              <span>{{ state.total - state.args.page * state.args.size - idx }}</span>
            </td>
            <td>
              <router-link class="pointer-u" :to="`/new-admin/member/members/${m.memberSeq}`">{{ m.memberId }}</router-link>
            </td>
            <td>
              <span>{{ m.memberName }}</span>
            </td>
            <td>
              <span>{{ m.corporateName || "(N/A)" }}</span>
            </td>
            <td>
              <span>{{ $lib.getPhoneNumberFormat(m.mobile, true) }}</span>
            </td>
            <td>
              <span>{{ getMemberType(m.memberType) }}</span>
            </td>
            <td>
              <span v-if="m.restFlag === 'Y'">휴면</span>
              <span v-else-if="m.leaveFlag === 'Y'">탈퇴</span>
              <span v-else>유효</span>
            </td>
            <td>
              <span>{{ m.createDate ? m.createDate.substring(0, 10) : "" }}</span>
            </td>
          </tr>
          </tbody>
        </table>
        <PaginationJpa className="btn-primary" :change="load" :total="state.total" :pageable="state.pageable" v-if="state.pageable.size"/>
      </div>
      <NoData v-else/>
    </div>
  </div>
</template>
<script>
import mixin from "@/scripts/mixin";
import http from "@/scripts/http";
import {defineComponent, reactive, watch} from "@vue/composition-api";
import PaginationJpa from "@/components/PaginationJpa";
import router from "@/scripts/router";
import lib from "@/scripts/lib";
import NoData from "@/components/NoData";
import Date from "@/components/Date";
import store from "@/scripts/store";

function Component(initialize) {
  this.name = "pageAdminMembersMemberList";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Date, NoData, PaginationJpa},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      load(undefined, true);
    });

    const state = reactive({
      loaded: false,
      args: {
        page: 0,
        size: 10,
      },
      search: {
        key: "memberId",
        value: "",
      },
      period: {
        key: "createDate",
        from: "",
        to: "",
      },
      conditions: {},
      total: 0,
      pageable: {},
      list: []
    });

    const search = [{
      name: "memberId",
      title: "아이디",
    }, {
      name: "memberName",
      title: "이름",
    }, {
      name: "mobile",
      title: "휴대전화",
    }, {
      name: "corporateName",
      title: "기업명",
    },];

    const period = [{
      name: "createDate",
      title: "가입일"
    }, {
      name: "loginDate",
      title: "최근 로그인 날짜"
    },];

    const conditions = [
      {
        title: "가입구분",
        name: "joinType",
        items: [{
          name: "facebook",
          title: "페이스북"
        }, {
          name: "naver",
          title: "네이버"
        }, {
          name: "kakao",
          title: "카카오"
        }, {
          name: "apple",
          title: "애플"
        }, {
          name: "google",
          title: "구글"
        }, {
          name: "homepage",
          title: "홈페이지"
        }]
      },
      {
        title: "회원권한",
        name: "memberAuth",
        items: [{
          name: "MEMBER_FRONT",
          title: "일반회원"
        }, {
          name: "MEMBER_MID_ADMIN",
          title: "중간 관리자"
        }, {
          name: "MEMBER_TOP_ADMIN",
          title: "최고 관리자"
        }]
      },
      {
        title: "회원 상태",
        name: "memberType",
        items: [{
          name: "P",
          title: "개인"
        }, {
          name: "I",
          title: "개인사업자"
        }, {
          name: "C",
          title: "법인"
        }]
      },
      {
        title: "투자자 인증 여부",
        name: "investorFlag",
        items: [{
          name: "Y",
          title: "인증"
        }, {
          name: "N",
          title: "미인증"
        },]
      },
      {
        title: "기타",
        items: [{
          name: "emailRecv",
          value: "Y",
          title: "이메일수신",
        }, {
          name: "smsRecv",
          value: "Y",
          title: "SMS 수신"
        }, {
          name: "leaveFlag",
          value: "N",
          title: "탈퇴회원제외"
        }],
      },];

    const getMemberType = (type) => {
      switch (type) {
        case "I":
          return "개인사업자";

        case "C":
          return "법인";
      }

      return "개인";
    };

    const load = (num) => {
      state.list = [];
      state.pageable.size = 0;
      state.pageable.page = 0;
      state.args = {
        page: 0,
        size: 0,
      };

      const page = window.Number(router.app.$route.query.page) || 0;
      (num !== undefined && num !== window.Number(page)) && pushToRouter("page", num);
      state.args.page = page;

      for (let i in router.app.$route.query) {
        if (state.conditions[i] && state.conditions[i] === router.app.$route.query[i]) {
          continue;
        }

        i !== "page" && (state.conditions[i] = router.app.$route.query[i]);
      }

      for (let i in state.conditions) {
        if (!router.app.$route.query[i]) {
          delete state.conditions[i];
        }

        if (state.conditions[i] && period.some(p => p.name === i)) {
          const date = state.conditions[i]?.split(" ~ ");
          state.args[i + "From"] = date[0];
          state.args[i + "To"] = date[1];
          state.period.key = period.find(p => p.name === i)?.name;
          state.period.from = lib.getDateFormat(new window.Date(date[0]), "yyyyMMdd");
          state.period.to = lib.getDateFormat(new window.Date(date[1]), "yyyyMMdd");
          continue;
        }

        state.args[i] = state.conditions[i];
      }

      window.scrollTo(0, 0);

      for (let i = 0; i < 10; i += 1) {
        state.list.push({
          memberId: "Wait a moment",
          memberName: "Wait a moment",
          mobile: "01012345678",
          createDate: "Wait a moment",
        });
      }

      state.loaded = false;
      http.get("/api/admin/members", state.args).then((res) => {
        if (JSON.stringify(state.args) === JSON.stringify(res.config.params)) {
          state.loaded = true;
          state.total = res.data.body.total;
          state.pageable = res.data.body.pageable;
          state.list = res.data.body.content;
        }
      });
    };

    const pushToRouter = (key, value) => {
      const query = lib.getRenewed(router.app.$route.query);

      if (search.some(s => s.name === key)) {
        for (let i in search) {
          delete query[search[i].name];
        }
      } else if (period.some(p => key.includes(p.name))) {
        for (let i in period) {
          delete query[period[i].name];
        }
      }

      return router.app.$route.query[key] !== value?.toString() && router.push({
        query: {
          ...query,
          [key]: value,
        }
      });
    };

    const setCondition = (key, value) => {
      if (!key || !value) {
        return;
      }

      pushToRouter("page", 0);

      if (state.conditions[key] === value) {
        return removeCondition(key);
      }

      state.conditions[key] = value;
      pushToRouter(key, value);
    };

    const removeCondition = (key) => {
      pushToRouter("page", 0);
      const query = lib.getRenewed(router.app.$route.query);

      delete query[key];
      delete state.conditions[key];

      router.push({query});
    };

    const getConditionFormat = (key, value) => {
      if (period.some(s => key.includes(s.name))) {
        return period.find(p => key.includes(p.name))?.title + ": " + value;
      } else if (search.some(s => s.name === key)) {
        return search.find(s => s.name === key)?.title + ": " + value;
      } else if (conditions.some(c => c.name === key)) {
        const section = conditions.find(c => c.name === key);
        return section?.title + ": " + section?.items?.find(i => i.name === value)?.title;
      } else if (!conditions.some(c => c.name === key)) {
        for (let c in conditions) {
          if (!conditions[c].name) {
            return conditions[c].items.find(i => i.name === key)?.title;
          }
        }
      }
    };

    const setPeriod = () => {
      if (new window.Date(lib.getDateFormat(state.period.from, "yyyy-MM-dd")) > new window.Date(lib.getDateFormat(state.period.to, "yyyy-MM-dd"))) {
        state.period.to = "";
        return store.commit("setSwingMessage", "종료일은 시작일보다 뒤의 날짜로 입력해주세요");
      }

      if (state.period.to && state.period.from) {
        pushToRouter("page", 0);
        pushToRouter(state.period.key, `${lib.getDateFormat(state.period.from, "yyyy-MM-dd")} ~ ${lib.getDateFormat(state.period.to, "yyyy-MM-dd")}`);
      }
    };

    watch(() => router.app.$route.query, (next, prev) => {
      if (next.modals || prev.modals) {
        return;
      }

      load();
    });

    return {component, state, conditions, search, load, getMemberType, setCondition, removeCondition, getConditionFormat, setPeriod};
  }
});
</script>
<style lang="scss" scoped>
.member-list {
  font-size: $px14;

  .header {
    padding-top: $px7;

    .left {
      .total {
        margin-bottom: $px12;
      }

      .conditions {
        margin-top: $px-8;
        margin-bottom: $px11;

        .condition {
          border: $px1 solid $colorPurple;
          border-radius: $px100;
          color: $colorPurple;
          display: inline-block;
          font-size: $px12;
          padding: $px4 $px12;
          margin: $px8 $px8 0 0;

          .remove {
            cursor: pointer;
            display: inline-block;
            margin-left: $px4;
          }
        }

        &::-webkit-scrollbar {
          height: $px3;
        }
      }
    }

    .search {
      width: auto;

      .period {
        margin-bottom: $px10;

        select, .date {
          width: 33.3333%;
        }

        .form-control {
          border-radius: 0;
          height: $px43;
          position: relative;
          left: $px2;

          &:focus {
            z-index: 1;
          }
        }

        &::v-deep {
          .date {
            .form-control {
              border-radius: 0;
              height: $px43;

              &:focus {
                z-index: 1;
              }
            }

            &:not(:last-child) .form-control {
              position: relative;
              left: $px1;
            }
          }
        }
      }

      .row {
        > div {
          select {
            position: relative;
            border-radius: $px4 0 0 $px4;
            left: $px1;

            &:focus {
              position: relative;
              z-index: 1;
            }
          }

          input[type=text] {
            border-radius: 0;
            border-right: 0;
            padding-right: $px34;
          }

          .btn {
            border-radius: 0 $px4 $px4 0;
            height: 100%;
          }

          .dropdown {
            width: 100%;

            .btn {
              padding: $px10 $px12;
              width: 100%;
              border: $px1 solid $colorBorder;
            }

            .dropdown-menu {
              padding: $px15;
              min-width: $px230;
              transform: translate3d($px-175, $px43, 0) !important;

              .section {
                .title {
                  font-size: $px14;
                  margin-bottom: $px4;
                }

                .dropdown-item {
                  border: $px1 solid $colorBorder;
                  border-radius: $px100;
                  cursor: pointer;
                  display: inline-block;
                  font-size: $px11;
                  padding: $px4 $px12;
                  margin: $px4 $px4 $px4 0;
                  width: auto;

                  &.active {
                    border-color: $colorPurple;
                    color: $colorPurple;
                  }
                }

                &:not(:last-child) {
                  margin-bottom: $px10;
                }
              }
            }
          }
        }
      }
    }
  }

  .body {
    padding-top: $px30;
    padding-bottom: $px30;

    table {
      margin-bottom: $px35;

      tr {
        th, td {
          padding-left: $px20;
          padding-right: $px20;
        }

        th {
          background: $colorBackground;
          border-bottom: 0;
        }
      }
    }
  }

  &.skeleton {
    .header {
      .total span {
        @include skeleton;
      }
    }

    .body {
      table tr {
        th, td {
          a, span {
            @include skeleton;
          }
        }
      }

      &::v-deep {
        .pagination {
          .btn {
            @include skeleton;
          }
        }
      }
    }
  }
}
</style>